/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

.popover-header {
  background-color: var(--bs-primary) !important;
  color: white !important;
}

.icon-pushback {
  background-image: url('./content/images/icons/pushback.png');
  vertical-align: text-bottom;
  height: 45px !important;
  width: 45px !important;
  background-size: cover;
}

.icon-jetway {
  background-image: url('./content/images/icons/gses/5 jetbridge.svg');
  vertical-align: text-bottom;
  height: 55px !important;
  width: 60px !important;
  background-size: cover;
}

.icon-plane-front {
  background-image: url('./content/images/icons/gses/1 plane front.svg');
  vertical-align: text-bottom;
  height: 30px !important;
  width: 40px !important;
  background-size: cover;
}

.icon-plane-departing {
  background-image: url('./content/images/icons/gses/3 departing.svg');
  width: 100% !important;
  height: 30px !important;
  background-size: 100% 100% !important;
  vertical-align: middle;
}

.icon-plane-arriving {
  background-image: url('./content/images/icons/gses/4 arriving.svg');
  width: 100% !important;
  height: 27px !important;
  background-size: 100% 100% !important;
  vertical-align: middle;
}

.icon-plane-asu {
  background-image: url('./content/images/icons/gses/8 asu.svg');
  vertical-align: text-bottom;
  height: 30px !important;
  width: 40px !important;
  background-size: cover;
}

.icon-plane-gpu {
  background-image: url('./content/images/icons/gses/9 gpu.svg');
  vertical-align: text-bottom;
  height: 30px !important;
  width: 40px !important;
  background-size: cover;
}

.icon-baggage-truck {
  background-image: url('./content/images/icons/gses/10 baggage truck - service.svg');
  vertical-align: text-bottom;
  height: 50px !important;
  width: 60px !important;
  background-size: cover;
}

.icon-cleaning-service-worker {
  background-image: url('./content/images/icons/gses/11 cleaning service worker.svg');
  vertical-align: text-bottom;
  height: 45px !important;
  width: 45px !important;
  background-size: cover;
}

.icon-fuel-service {
  background-image: url('./content/images/icons/gses/12 fuel service.svg');
  vertical-align: text-bottom;
  height: 38px !important;
  width: 38px !important;
  background-size: cover;
}

.icon-highloader {
  background-image: url('./content/images/icons/gses/13 highloader.svg');
  vertical-align: text-bottom;
  height: 45px !important;
  width: 45px !important;
  background-size: cover;
}

.icon-marshaling {
  background-image: url('./content/images/icons/gses/14 marshaling.svg');
  vertical-align: text-bottom;
  height: 40px !important;
  width: 40px !important;
  background-size: cover;
}

.icon-wc {
  background-image: url('./content/images/icons/gses/21 WC.svg');
  vertical-align: text-bottom;
  height: 40px !important;
  width: 40px !important;
  background-size: cover;
}

.icon-bus {
  background-image: url('./content/images/icons/gses/15 bus.svg');
  vertical-align: text-bottom;
  height: 45px !important;
  width: 45px !important;
  background-size: cover;
}

.icon-message {
  background-image: url('./content/images/icons/Message.svg');
  vertical-align: text-bottom;
  height: 33px !important;
  width: 32px !important;
  background-size: cover;
}

.icon-towing {
  background-image: url('./content/images/icons/gses/16 towing.svg');
  vertical-align: text-bottom;
  height: 45px !important;
  width: 50px !important;
  background-size: cover;
}

.icon-deicing {
  background-image: url('./content/images/icons/gses/17 de-icing.svg');
  vertical-align: text-bottom;
  height: 32px !important;
  width: 32px !important;
  background-size: cover;
}

.icon-ambulance {
  background-image: url('./content/images/icons/gses/18 ambulance.svg');
  vertical-align: text-bottom;
  height: 45px !important;
  width: 45px !important;
  background-size: cover;
}

.icon-firetruck {
  background-image: url('./content/images/icons/gses/19 firetruck.svg');
  vertical-align: text-bottom;
  height: 45px !important;
  width: 45px !important;
  background-size: cover;
}

.icon-tower {
  background-image: url('./content/images/icons/gses/20 tower.svg');
  vertical-align: text-bottom;
  height: 45px !important;
  width: 45px !important;
  background-size: cover;
}

.icon-airplane {
  background-image: url('content/images/icons/22 airplane.svg');
  vertical-align: text-bottom;
  height: 34px !important;
  width: 34px !important;
  background-size: cover;
}

.icon-stairs {
  background-image: url('./content/images/icons/gses/2 stairs.svg');
  vertical-align: text-bottom;
  height: 45px !important;
  width: 55px !important;
  background-size: cover;
}

.icon-stairs2 {
  background-image: url('./content/images/new/truck stairs avitium - full.svg');
  vertical-align: text-bottom;
  height: 30px !important;
  width: 46px !important;
  background-size: cover;
}

.icon-stairs3 {
  background-image: url('./content/images/new/truck stairs avitium -lines.svg');
  vertical-align: text-bottom;
  height: 30px !important;
  width: 46px !important;
  background-size: cover;
}

.fs-38 {
  font-size: 38px !important;
}

.gse-color {
  color: #aaa !important;
}

@font-face {
  font-family: "Helvetica Light";
  src: local("Helvetica Light"), url("content/fonts/helvetica-light.ttf") format("truetype");
}

.chat-font {
  font-family: 'Helvetica Light';
}

.chat-search-popover-size {
  width: 380px !important;
  max-width: 380px !important;
  overflow: auto;
}

// Custom scrollbar
.avitium-scrollbar::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.15);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.avitium-scrollbar::-webkit-scrollbar
{
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}

.avitium-scrollbar::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #d2d2d2;
}

// Dropdown scrollbar
.ng-dropdown-panel-items::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.15);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.ng-dropdown-panel-items::-webkit-scrollbar
{
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}

.ng-dropdown-panel-items::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #d2d2d2;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}
.vertical-middle {
  vertical-align: middle;
}

.modal-title {
  font-size: 18px !important;
}
.form-field-header {
  font-size: 18px !important;
  font-weight: 300 !important;
}
.accordion-text {
  font-size: 18px;
}

.min-w-1100 {
  min-width: 1100px !important;
}

.children-fs16 * {
  font-size: 16px !important;
}

.children-fs18 * {
  font-size: 18px !important;
}

input[ngbdatepicker], input.ngb-tp-input {
  font-weight: 300 !important;
}

.form-select {
  font-weight: 300 !important;
}
.btn {
  font-weight: 300 !important;
}
.accordion-item {
  border: none !important;
}
.accordion-item-content-container {
  border: 1px solid #dfdfdf !important;
}

.modal-500px {
  max-width: 500px !important;
}

.flex-basis-60 {
  flex-basis: 60% !important;
}

.light-blue-border {
  border-color: #C0D8ED !important;
  border-radius: 5px;
  border: 1px solid;
}

select.light-blue-border.ng-invalid {
  border-left-color: red !important;
}

.light-blue-border-bottom {
  border-color: #C0D8ED !important;
  border-radius: 0;
  border-bottom: 1px solid;
}

.input-color {
  color: #ECF4FE !important;
}

.input-bg-color {
  background-color: #ECF4FE !important;;
}

.dark-blue {
  color: #002060 !important;
}

ul.dark-blue li {
    color: #002060 !important;
}

.bg-light-gray {
  background-color: #F2F2F2 !important;;
}

.avitium-orange
{
  color: #F78871 !important;;
}

.button-blue {
  background-color: #4285F6 !important;
  color: white !important;
}
.button-blue:hover {
  background-color: #3964FF !important;
}

.button-blue-outlined {
  border: 1px solid #4285F6 !important;
  color: #4285F6 !important;
  background-color: white !important;
}
.button-blue-outlined:hover {
  color: white !important;
  background-color: #4285F6 !important;
}


.form-check-input:checked {
  border-color: #4285F6 !important;
  background-color: #4285F6 !important;
}

:root {
  --avitium-orange: #F78871;
  --avitium-blue: #4285F6;
}

.modal-header {
  background-color: white;
  color: #002060;
  font-weight: 700;
  border-bottom-color: #C0D8ED !important;
}

.modal-footer {
  border-top-color: #C0D8ED !important;
}


.btn-close {
  background: transparent url("/content/images/icons/close-x.svg") center/1em auto no-repeat !important;
  opacity: 1 !important;
}

ngb-timepicker input {
  border-color: #C0D8ED !important;
}

.form-control {
  border-radius: 5px !important;
  border: 1px solid #C0D8ED !important;
  &.ng-invalid {
    border-left: 5px solid red !important;
  }
}

button.icon-icons8-calendar {
  border-radius: 5px !important;
  border: 1px solid #C0D8ED !important;
}

input {
  color: #002060 !important;
}

.form-select {
  color: #002060 !important;
}

.light-blue-bg {
  background-color: var(--avitium-blue) !important;
}

ngb-timepicker.ng-invalid {
  border-left: none !important;
}

ngb-timepicker.ng-invalid > fieldset > div.ngb-tp > div.ngb-tp-input-container.ngb-tp-hour > input {
  border-left: 5px solid red !important;
}

.cursor-pointer {
  cursor: pointer;
}

.table {
  border-bottom-color: #C0D8ED !important;
}

th.desc::before, th.asc::before {
  content: "";
  display: block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC) no-repeat;
  background-size: 22px;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

th.desc::before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.control-blue-bg {
  background-color: #ECF4FE !important;
}

.ng-option-disabled {
  font-size: 14px;
}
.ng-option-label {
  font-size: 14px !important;
  color: #002060 !important;
}
.ng-value, .ng-input input {
  color: #002060;
  font-size: 14px;
  font-weight: 300;
}
.ng-select {
  border-radius: 5px !important;
  border: 1px solid #C0D8ED;
}
.ng-select.ng-select-opened>.ng-select-container{
  background:#fff;
  //border-color:#b3b3b3 #ccc #d9d9d9
}
.ng-select.ng-select-opened>.ng-select-container:hover{
  box-shadow:none
}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow{
  top:-2px;
  border-color:transparent transparent #999;
  border-width:0 5px 5px
}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover{
  border-color:transparent transparent #333
}
.ng-select.ng-select-opened.ng-select-top>.ng-select-container{
  border-top-right-radius:0;
  border-top-left-radius:0
}
.ng-select.ng-select-opened.ng-select-right>.ng-select-container{
  border-top-right-radius:0;
  border-bottom-right-radius:0
}
.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container{
  border-bottom-right-radius:0;
  border-bottom-left-radius:0
}
.ng-select.ng-select-opened.ng-select-left>.ng-select-container{
  border-top-left-radius:0;
  border-bottom-left-radius:0
}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
  border-color:#007eff;
  box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 0 3px rgba(0,126,255,0.1)
}
.ng-select.ng-select-disabled>.ng-select-container{
  background-color:#e9ecef
}
.ng-select .ng-has-value .ng-placeholder{
  display:none
}
.ng-select .ng-select-container{
  color:#333;
  background-color:#fff;
  min-height:36px;
  align-items:center
}
.ng-select .ng-select-container:hover{
  box-shadow:0 1px 0 rgba(0,0,0,0.06)
}
.ng-select .ng-select-container .ng-value-container{
  align-items:center;
  padding-left:10px
}
[dir="rtl"] .ng-select .ng-select-container .ng-value-container{
  padding-right:10px;
  padding-left:0
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder{
  color:#999
}
.ng-select.ng-select-single .ng-select-container{
  height:36px
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{
  top:5px;
  left:0;
  padding-left:10px;
  padding-right:50px
}
[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{
  padding-right:10px;
  padding-left:50px
}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value{
  background-color:#f9f9f9;
  border:1px solid #e6e6e6
}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label{
  padding:0 5px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container{
  padding-top:5px;
  padding-left:7px
}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container{
  padding-right:7px;
  padding-left:0
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
  font-size:.9em;
  margin-bottom:5px;
  color:#333;
  background-color:#ebf5ff;
  border-radius:2px;
  margin-right:5px
}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
  margin-right:0;
  margin-left:5px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled{
  background-color:#f9f9f9
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{
  padding-left:5px
}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{
  padding-left:0;
  padding-right:5px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label{
  display:inline-block;
  padding:1px 5px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon{
  display:inline-block;
  padding:1px 5px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover{
  background-color:#d1e8ff
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{
  border-right:1px solid #b8dbff
}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{
  border-left:1px solid #b8dbff;
  border-right:none
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{
  border-left:1px solid #b8dbff
}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{
  border-left:0;
  border-right:1px solid #b8dbff
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{
  padding:0 0 3px 3px
}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{
  padding:0 3px 3px 0
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input{
  color:#000
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{
  top:5px;
  padding-bottom:5px;
  padding-left:3px
}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{
  padding-right:3px;
  padding-left:0
}
.ng-select .ng-clear-wrapper{
  color:#999
}
.ng-select .ng-clear-wrapper:hover .ng-clear{
  color:#D0021B
}
.ng-select .ng-spinner-zone{
  padding:5px 5px 0 0
}
[dir="rtl"] .ng-select .ng-spinner-zone{
  padding:5px 0 0 5px
}
.ng-select .ng-arrow-wrapper{
  width:25px;
  padding-right:5px
}
[dir="rtl"] .ng-select .ng-arrow-wrapper{
  padding-left:5px;
  padding-right:0
}
.ng-select .ng-arrow-wrapper:hover .ng-arrow{
  border-top-color:#666
}
.ng-select .ng-arrow-wrapper .ng-arrow{
  border-color:#999 transparent transparent;
  border-style:solid;
  border-width:5px 5px 2.5px
}
.ng-dropdown-panel{
  background-color:#fff;
  border:1px solid #ccc;
  box-shadow:0 1px 0 rgba(0,0,0,0.06);
  left:0;
  min-width: 100%;
  width: max-content !important;
  max-width: 450px;
}
.ng-dropdown-panel.ng-select-top{
  bottom:100%;
  border-top-right-radius:4px;
  border-top-left-radius:4px;
  border-bottom-color:#e6e6e6;
  margin-bottom:-1px
}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child{
  border-top-right-radius:4px;
  border-top-left-radius:4px
}
.ng-dropdown-panel.ng-select-right{
  left:100%;
  top:0;
  border-top-right-radius:4px;
  border-bottom-right-radius:4px;
  border-bottom-left-radius:4px;
  border-bottom-color:#e6e6e6;
  margin-bottom:-1px
}
.ng-dropdown-panel.ng-select-right .ng-dropdown-panel-items .ng-option:first-child{
  border-top-right-radius:4px
}
.ng-dropdown-panel.ng-select-bottom{
  top:100%;
  border-bottom-right-radius:4px;
  border-bottom-left-radius:4px;
  border-top-color:#e6e6e6;
  margin-top:-1px
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child{
  border-bottom-right-radius:4px;
  border-bottom-left-radius:4px
}
.ng-dropdown-panel.ng-select-left{
  left:-100%;
  top:0;
  border-top-left-radius:4px;
  border-bottom-right-radius:4px;
  border-bottom-left-radius:4px;
  border-bottom-color:#e6e6e6;
  margin-bottom:-1px
}
.ng-dropdown-panel.ng-select-left .ng-dropdown-panel-items .ng-option:first-child{
  border-top-left-radius:4px
}
.ng-dropdown-panel .ng-dropdown-header{
  border-bottom:1px solid #ccc;
  padding:5px 7px
}
.ng-dropdown-panel .ng-dropdown-footer{
  border-top:1px solid #ccc;
  padding:5px 7px
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup{
  user-select:none;
  padding:8px 10px;
  font-weight:500;
  color:rgba(0,0,0,0.54);
  cursor:pointer
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled{
  cursor:default
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked{
  background-color:#f5faff
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked{
  color:rgba(0,0,0,0.54);
  background-color:#ebf5ff;
  font-weight:600
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
  background-color:#fff;
  color: #002060;
  padding:8px 10px
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{
  color:#333;
  background-color:#ebf5ff
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label{
  font-weight:600;
  font-size: 14px !important;
  color: #002060 !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{
  background-color:#f5faff;
  color:#333
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled{
  color:#ccc
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{
  padding-left:22px
}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{
  padding-right:22px;
  padding-left:0
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{
  font-size:80%;
  font-weight:400;
  padding-right:5px
}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{
  padding-left:5px;
  padding-right:0
}
[dir="rtl"] .ng-dropdown-panel{
  direction:rtl;
  text-align:right
}
